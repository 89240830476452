import React from 'react'
import {useEffect} from 'react';
import Navbar from '../components/navbar'; 
import HeaderSection from '../components/HeaderSection';
import ChairmanSection from '../components/ChairmanSection';
import TeamSection from '../components/TeamSection';
import Services_section from '../components/services_section';
import Footer_section from '../components/footer_section'
import AboutUsImage from '../images/About-Us.webp'



function AboutUs(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
        <Navbar active={props.active}/>
        <HeaderSection primaryHeader="C.DG" secondaryHeader="About Us"
        back_images={AboutUsImage} />
        <ChairmanSection/>
        <TeamSection/>
        <Footer_section/>
    </>
    
  )
}

export default AboutUs