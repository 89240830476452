import React from 'react'
import back_images from '../images/heroBackground.webp'
import './Hero_section.css'

function Hero_section() {
  return (
    <div className='hero-container' style={{
        backgroundImage: `url(${back_images})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'

    }}>
        <div className="word-container">
            <p>WELCOME TO <span>C.DG</span></p>
            <h3>We Are Connecting People By Quality</h3>
        </div>
    </div>
  )
}

export default Hero_section