import React from 'react'
import './HeaderStyle.css'

function HeaderSection(props) {
  return (
    <>
        <div className='Header-Containeer'>
            <div className='Image-Placeholder'
            style={{
                backgroundImage: `url(${props.back_images})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
        
            }}
            >
                <div className='Header-Overlay'>
                    <h3>{props.secondaryHeader}</h3>
                    <h1>{props.primaryHeader}</h1>
                </div>
            </div>
        </div>
    </>
  )
}

export default HeaderSection