import React from 'react'
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './services.css'
import img1 from '../images/latestprojects/4.webp'
import img2 from '../images/latestprojects/2.webp'
import img3 from '../images/latestprojects/1.webp'
import img4 from '../images/latestprojects/3.webp'
import bim_back_images from '../images/bim/1.webp'



function Services_section() {
    const navigate = useNavigate();
    const [background, setBackground] = useState(img1);
    let child='1';
    const changeBackground=()=>{setTimeout(()=>{
        if(child==='1') setBackground(img1)
        else if(child==='2') setBackground(img2)
        else if(child==='3') setBackground(img3)
        else if(child==='4') setBackground(img4)
    },500)}
    const updateBackground=(event)=>{
        child=event.currentTarget.id;
        clearTimeout(changeBackground)
        changeBackground()
        
    }
    const goToServices=()=>{

    }
  return (
    <div className='services-container' id='services'>
        <h2>What We Do</h2>
        <h1>Our Services</h1>
        <div className='sub_container'>
                <div className='services-columns' style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'

                }}>
                    <div className='column column-border' id='2' onMouseEnter={updateBackground} onClick={() => {navigate('/services/bim-and-brim')}}>
                        <h1>BIM & BRIM</h1>
                        <div className='column-btn'>
                            Explore More
                        </div>
                    </div>
                    <div className='img1' style={{
                    backgroundImage: `url(${bim_back_images})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}></div>
                    <div className='column column-border' id='2' onMouseEnter={updateBackground} onClick={() => {navigate('/services/residential-and-commercial-buildings')}}>
                        <h1>Residential & Commercial Buildings</h1>
                        <div className='column-btn'>
                            Explore More
                        </div>
                    </div>
                    <div className='img1' style={{
                    backgroundImage: `url(${img2})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}></div>
                    <div className='column column-border main-column' id='1' onMouseEnter={updateBackground} onClick={() => {navigate('/services/bridges')}}>
                        <h1>Bridges</h1>
                        <div className='column-btn'>
                            Explore More
                        </div>
                    </div>
                    <div className='img1' style={{
                    backgroundImage: `url(${img1})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}></div>
                    <div className='column column-border' id='3' onMouseEnter={updateBackground} onClick={() => {navigate('/services/prestressing-details-and-shop-drawings')}}>
                        <h1>Prestressing Details and Shop Drawings</h1>
                        <div className='column-btn'>
                            Explore More
                        </div>
                    </div>
                    <div className='img1' style={{
                    backgroundImage: `url(${img3})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}></div>
                    <div className='column' id='4' onMouseEnter={updateBackground} onClick={() => {navigate('/services/value-engineering')}}>
                        <h1>Value Engineering</h1>
                        <div className='column-btn'>
                            Explore More
                        </div>
                    </div>
                    <div className='img1' style={{
                    backgroundImage: `url(${img4})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}></div>
            </div>
        </div>
        <div className='absolute-color'></div>

    </div>
  )
}

export default Services_section