import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Card.css'

function Card(props) {
  const navigate = useNavigate();
  return (
    <div className='card'>
        <img src={props.ImageSource}/>
        <div className='intro' onClick={() => {navigate('/projects/'+props.URL,{projectId:props.URL})}}>
            <h1>{props.ProjectName}</h1>
            <div className='column-btn'>
                Explore
            </div>
        </div>
    </div>
  )
}

export default Card