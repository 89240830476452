import React from 'react'
import './ImageCard2.css'

function ImageCard2(props) {
  return (
    <div className='image-card2'>
        <img src={props.ImageSource}/>
        <h1 className='H1'>{props.Name}</h1>
    </div>
  )
}

export default ImageCard2