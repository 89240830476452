import React from 'react'
import { useState, useEffect, useRef } from "react";
import styled from 'styled-components'

export const H1 = styled('h1')`
  text-align: center;
  margin: 0;
  padding-bottom: 10rem;
`
export const Relative = styled('div')`
  position: relative;
`
export const Flex = styled('div')`
  display: flex;
`
export const HorizontalCenter = styled(Flex)`
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
`
export const Container = styled('div')`
  height: 100vh;
  width: 100%;
  background: #ecf0f1;
`
export const Item = styled('div')`
  color: white;
  font-size: 2rem;
  text-transform: capitalize;
  width: ${({size}) => `${size}rem`};
  height: ${({size}) => `${size}rem`};
  display: flex;
  align-items: center;
  justify-content: center;
`


function getPrevElement(list) {
const sibling = list[0].previousElementSibling

if (sibling instanceof HTMLElement) {
    return sibling
}

return sibling
}
  
function getNextElement(list) {
const sibling = list[list.length - 1].nextElementSibling

if (sibling instanceof HTMLElement) {
    return sibling
}

return null
}
  
function usePosition(ref) {
const [prevElement, setPrevElement] = useState(null)
const [nextElement, setNextElement] = useState(null)

useEffect(() => {
    const element = ref.current

    const update = () => {
    const rect = element.getBoundingClientRect()
    
    const visibleElements = Array.from(element.children).filter((child) => {
        const childRect = child.getBoundingClientRect()
        

        return childRect.left >= rect.left && childRect.right <= rect.right
    })

    if (visibleElements.length > 0) {
        setPrevElement(getPrevElement(visibleElements))
        setNextElement(getNextElement(visibleElements))
    }
    }
  
      update()
  
      element.addEventListener('scroll', update, {passive: true})
  
      return () => {
        element.removeEventListener('scroll', update, {passive: true})
      }
    }, [ref])
  
    const scrollToElement = React.useCallback(
      (element) => {
        const currentNode = ref.current
  
        if (!currentNode || !element) return
  
        let newScrollPosition
  
        newScrollPosition =
          element.offsetLeft +
          element.getBoundingClientRect().width / 2 -
          currentNode.getBoundingClientRect().width / 2
  
        currentNode.scroll({
          left: newScrollPosition,
          behavior: 'smooth',
        })
      },
      [ref],
    )
  
    const scrollRight = React.useCallback(() => scrollToElement(nextElement), [
      scrollToElement,
      nextElement,
    ])
  
    const scrollLeft = React.useCallback(() => scrollToElement(prevElement), [
      scrollToElement,
      prevElement,
    ])
  
    return {
      hasItemsOnLeft: prevElement !== null,
      hasItemsOnRight: nextElement !== null,
      scrollRight,
      scrollLeft,
    }
  }
  

const CarouserContainer = styled(Relative)`
  overflow: hidden;
`


 // Carousel.styled.js

// Position and style the buttons
export const CarouselButton = styled('button')`
position: absolute;
cursor: pointer;
top: 50%;
z-index: 200000;
background: white;
border-radius: 15px;
border: none;
padding: 0.5rem;
width:70px
`

// Display buttons on hover
export const LeftCarouselButton = styled(CarouselButton)`
left: 0;
transform: translate(-100%, -50%);

  ${CarouserContainer}:hover & {
    transform: translate(0%, -50%);
  }
`

// Position the buttons to their respective sides
export const RightCarouselButton = styled(CarouselButton)`
right: 0;
transform: translate(100%, -50%);

  ${CarouserContainer}:hover & {
    transform: translate(0%, -50%);
  }
`

 const CarouserContainerInner = styled(Flex)`
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  // offset for children spacing
  margin-left: -1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    margin-left: 1rem;
    scroll-snap-align: center;
  }


`
// ArrowLeft
export const ArrowLeft = ({size = 30, color = '#000000'}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 12H6M12 5l-7 7 7 7" />
    </svg>
  )
  
  // ArrowRight
  export const ArrowRight = ({size = 30, color = '#000000'}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h13M12 5l7 7-7 7" />
    </svg>
  )


let flag=false

export const  Carousel= function({index,children}) {
    const ref = useRef()
    const element = ref.current
    if(!element) flag=false
    if(!flag){
      let child=undefined;
      if(element){
        child=element.children[index]
        flag=true
      }
  
      const currentNode = ref.current
    
      if (!currentNode || !child){}
      else{
        let newScrollPosition
  
        newScrollPosition =
          child.offsetLeft +
          child.getBoundingClientRect().width / 2 -
          currentNode.getBoundingClientRect().width / 2
        console.log("pos",newScrollPosition)
        currentNode.scroll({
          left: newScrollPosition,
          behavior: 'smooth',
        })
      }
    }
    
    const {
      hasItemsOnLeft,
      hasItemsOnRight,
      scrollRight,
      scrollLeft,
    } = usePosition(ref)
  return (
    <CarouserContainer>
        <CarouserContainerInner ref={ref}>
            {children}
        </CarouserContainerInner>
        {hasItemsOnLeft && <LeftCarouselButton hasItemsOnLeft={hasItemsOnLeft} onClick={scrollLeft}>
            <ArrowLeft />
        </LeftCarouselButton>}

        {hasItemsOnRight &&<RightCarouselButton hasItemsOnRight={hasItemsOnRight} onClick={scrollRight}>
            <ArrowRight />
        </RightCarouselButton>}
    </CarouserContainer>
  )
}

