import React from 'react'
import {useEffect} from 'react';
import {useParams} from 'react-router-dom'

import Navbar from '../components/navbar'; 
import HeaderSection from '../components/HeaderSection';
import ServiceDescription from '../components/ServiceDescription'; 
import RelatedWorks from '../components/RelatedWorks'; 
import Footer_section from '../components/footer_section'

import bim_back_images from '../images/bim/1.webp'
import bridges_back_images from '../images/latestprojects/4.webp'
import residential_back_images from '../images/latestprojects/2.webp'
import prestressing_back_images from '../images/latestprojects/1.webp'
import value_back_images from '../images/latestprojects/3.webp'

import murciaImg from '../images/projects/Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform/1.webp'
import dryImg from '../images/projects/Dry-Dock-Highway-AL-BAHRAIN/1.webp'
import warraqImg from '../images/projects/Warraq-Nile-Bridge/1.webp'
import HSR from '../images/projects/HSR-High-Speed-Rail/1.webp'
import Mansoura1 from '../images/projects/Mansoura-Bridge/1.webp'
import AS1 from '../images/projects/EL-Salam-International-Hospital/1.webp'
import img1 from '../images/bridges/1.webp'
import img2 from '../images/bridges/2.webp'
import img3 from '../images/bridges/3.webp'
import img4 from '../images/bridges/4.webp'
import img5 from '../images/bridges/5.webp'


import bimImg1 from '../images/bim/1.webp'
import bimImg2 from '../images/bim/2.webp'
import bimImg3 from '../images/bim/3.webp'
import bimImg4 from '../images/bim/4.webp'
import bimImg5 from '../images/bim/5.webp'
import bimImg6 from '../images/bim/6.webp'
import bimImg7 from '../images/bim/7.webp'
import bimImg8 from '../images/bim/8.webp'
import bimImg9 from '../images/bim/9.webp'
import bimImg10 from '../images/bim/10.webp'
import bimImg11 from '../images/bim/11.webp'
import bimImg12 from '../images/bim/12.webp'




function Services(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const params=useParams();
  if(params.serviceId) console.log(params.serviceId)
  let Services=[
    {'primaryHeader':'Bridges','secondaryHeader':'Our Services','image':bridges_back_images, 'description1':'C.DG has designed all bridges/viaduct types. Prestressed box girders, precast prestressed beams, voided slabs, two balanced cantilevers and cable stayed bridges. We provide value engineering for most efficient construction techniques.  We are expert in providing many architecture features to enhance the bridges aesthetics.  We are unique for the design, technical assistance for cut and cover underpasses. ', 'description2':'We also combine our experience and new technologies to deliver safer and more reliable bridge engineering to our clients. We offer outstanding project management covering the entire lifecycle of the project. We Conform to all procedures and processes in accordance with contract specifications and industry standards.'
  ,'projects':[
    {'project-name':'Warraq Nile Bridge','url':'Warraq-Nile-Bridge','image':warraqImg},
    {'project-name':'HSR-High Speed Rail','url':'HSR-High-Speed-Rail','image':HSR},
    {'project-name':'Murcia-Almeria High Speed Mediterranean Corridor Platform Spain','url':'Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform','image':murciaImg},
    {'project-name':'Mansoura Bridge','url':'Mansoura-Bridge','image':Mansoura1},
    {'project-name':'Dry Dock Highway, AL BAHRAIN','url':'Dry-Dock-Highway-AL-BAHRAIN','image':dryImg}
  ],'images':[img1,img2,img3,img4,img5]},
    {'primaryHeader':'Residential and Commercial Buildings','secondaryHeader':'Our Services','image':residential_back_images, 'description1':'C.DG has designed high story buildings subjected to seismic analysis and sport facilities such as stadiums with sophisticated roofing. and We have wide supervision experience in construction supervision such as residential      compounds and retail buildings and Our services also include planning, design, engineering and project management.'
    ,'projects':[
      {'project-name':'EL-Salam International Hospital','url':'EL-Salam-International-Hospital','image':AS1}
    ],'images':[]},
    {'primaryHeader':'Prestressing Details and Shop Drawings','secondaryHeader':'Our Services','image':prestressing_back_images, 'description1':'C.DG has a wide experience in prestressing details and the preparation of accurate post tensioned cable profiles for all types of bridges and structures using very accurate up to date software’s. We have specialists in shop drawings using many advanced programs.', 'description2':'C.DG has wide experiences in shop drawing using Revit. C.DG has professional meddlers to create 3D for the bridges and install the reinforcement and prestressed cables with zero clashes. The way allows revisit/revise any reinforcement groups in no time to fulfil any client comments.\n\nC.DG also has a team of experts in Building Information Modeling to ensure efficient management of information and high-quality collaboration.'
    ,'projects':[
      {'project-name':'Warraq Nile Bridge','url':'Warraq-Nile-Bridge','image':warraqImg}
    ]
    ,'images':[]},
    {'primaryHeader':'Value Engineering','secondaryHeader':'Our Services','image':value_back_images, 'description1':'C.DG creates the knowledge, the imagination and the ambition to achieve so many of today’s greatest pressing challenges.', 'description2':'Lately value engineering has become essential for all projects. We have conducted many value engineering reports and ideas. The saving was 20 to 40% of the project cost. We have also done cost reduction while maintaining the project operation functions and soundness.'
    ,'projects':[]
    ,'images':[]},
    {'primaryHeader':'BIM & BRIM','secondaryHeader':'Our Services','image':bim_back_images, 'description1':'C.DG use BIM modelling and high-end softwares to ensure high category of detailing based on the project LOD level. We make sure that all our detailing and drawings contain zero clashes and are error free.', 'description2':''
  ,'projects':[],
  'images':[bimImg1,bimImg2,bimImg3,bimImg4,bimImg5,bimImg6,bimImg7,bimImg8,bimImg8,bimImg9,bimImg10,bimImg11,bimImg12]}
  ]
  let dect={
    'bridges':0,
    'residential-and-commercial-buildings':1,
    'prestressing-details-and-shop-drawings':2,
    'value-engineering':3,
    'bim-and-brim':4
}
  return (
    <>
        <Navbar active={props.active}/>     
        <HeaderSection primaryHeader={Services[dect[params.serviceId]]['primaryHeader']} secondaryHeader={Services[dect[params.serviceId]]['secondaryHeader']}
        back_images={Services[dect[params.serviceId]]['image']} />
        <ServiceDescription description1={Services[dect[params.serviceId]]['description1']} description2={Services[dect[params.serviceId]]['description2']}/>
        {(Services[dect[params.serviceId]]['projects'].length>0 ||Services[dect[params.serviceId]]['images'].length>0)  && <RelatedWorks secondary="Projects" primary="Related Works" images={Services[dect[params.serviceId]]['images']} projects={Services[dect[params.serviceId]]['projects']}/>}
        <Footer_section/>
    </>
    
  )
}

export default Services