import {Routes,Route} from 'react-router-dom'
import './App.css';
import Home from './pages/Home'
import Services from './pages/Services'
import AboutUs from './pages/about-us'
import Projects from './pages/Projects'

function App() {
  return (
    <Routes>
        <Route path='/' element={<Home active='1'/>}></Route>
        <Route path='/services' element={<Services active='2'/>}>
          <Route path=':serviceId' element={<Services active='2'/>}/>
        </Route>
        <Route path='/aboutus' element={<AboutUs active='4'/>}></Route>
        <Route path='/projects' element={<Projects active='3'/>}>
          <Route path=':projectId' element={<Projects active='3'/>}/>
        </Route>
    </Routes>
  );
}

export default App;
