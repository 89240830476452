import React from 'react'
import {useEffect} from 'react';
import {useParams} from 'react-router-dom'
import Navbar from '../components/navbar'; 
import HeaderSection from '../components/HeaderSection';
import ServiceDescription from '../components/ServiceDescription'; 
import RelatedWorks from '../components/RelatedWorks'; 
import Footer_section from '../components/footer_section'
import CollageImage from '../images/projects/collage.webp'

import img1 from '../images/bridges/1.webp'
import img2 from '../images/bridges/2.webp'
import img3 from '../images/bridges/3.webp'
import img4 from '../images/bridges/4.webp'

import warraq1 from '../images/projects/Warraq-Nile-Bridge/1.webp'
import warraq2 from '../images/projects/Warraq-Nile-Bridge/2.webp'
import warraq3 from '../images/projects/Warraq-Nile-Bridge/3.webp'
import warraq4 from '../images/projects/Warraq-Nile-Bridge/4.webp'
import warraq5 from '../images/projects/Warraq-Nile-Bridge/5.webp'
import warraq6 from '../images/projects/Warraq-Nile-Bridge/6.webp'
import warraq7 from '../images/projects/Warraq-Nile-Bridge/7.webp'
import warraq8 from '../images/projects/Warraq-Nile-Bridge/8.webp'


import HSR1 from '../images/projects/HSR-High-Speed-Rail/1.webp'
import HSR2 from '../images/projects/HSR-High-Speed-Rail/2.webp'
import HSR3 from '../images/projects/HSR-High-Speed-Rail/3.webp'
import HSR4 from '../images/projects/HSR-High-Speed-Rail/4.webp'
import HSR5 from '../images/projects/HSR-High-Speed-Rail/5.webp'
import HSR6 from '../images/projects/HSR-High-Speed-Rail/6.webp'
import HSR7 from '../images/projects/HSR-High-Speed-Rail/7.webp'
import HSR8 from '../images/projects/HSR-High-Speed-Rail/8.webp'
import HSR9 from '../images/projects/HSR-High-Speed-Rail/9.webp'
import HSR10 from '../images/projects/HSR-High-Speed-Rail/10.webp'
import HSR11 from '../images/projects/HSR-High-Speed-Rail/11.webp'
import HSR12 from '../images/projects/HSR-High-Speed-Rail/12.webp'
import HSR13 from '../images/projects/HSR-High-Speed-Rail/13.webp'
import HSR14 from '../images/projects/HSR-High-Speed-Rail/14.webp'
import HSR15 from '../images/projects/Warraq-Nile-Bridge/9.webp'



import Mansoura1 from '../images/projects/Mansoura-Bridge/1.webp'
import Mansoura2 from '../images/projects/Mansoura-Bridge/2.webp'
import Mansoura3 from '../images/projects/Mansoura-Bridge/3.webp'
import Mansoura4 from '../images/projects/Mansoura-Bridge/4.webp'


import AS1 from '../images/projects/EL-Salam-International-Hospital/1.webp'
import AS2 from '../images/projects/EL-Salam-International-Hospital/2.webp'

import murciaImg1 from '../images/projects/Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform/1.webp'
import murciaImg2 from '../images/projects/Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform/2.webp'
import murciaImg3 from '../images/projects/Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform/3.webp'
import murciaImg4 from '../images/projects/Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform/4.webp'

import dryImg1 from '../images/projects/Dry-Dock-Highway-AL-BAHRAIN/1.webp'
import dryImg2 from '../images/projects/Dry-Dock-Highway-AL-BAHRAIN/2.webp'
import dryImg3 from '../images/projects/Dry-Dock-Highway-AL-BAHRAIN/3.webp'




function Projects(props) {
    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    const params=useParams();
    if(params.projectId) console.log(params.projectId)
    let projects=[
        {'project-name':'Warraq Nile Bridge','description1':'The project includes the construction of a new bridge beside an existing one which connects the East Bank of the Nile (Cairo-Kanater Road) and the West bank (Embaba-Kanater road) running over the Nile and the North side of the Warraq island. ','description2':'The viaduct includes segmental long span parts over the Nile River.','url':'Warraq-Nile-Bridge','image':warraq1, 'images':[warraq1,warraq2,warraq3,warraq4,warraq5,warraq6,warraq7,warraq8]},
        {'project-name':'HSR-High Speed Rail','description1':'The project was launched in 2021 to construct three high speed rail lines with a total length of about 2,000 kilometers (1,200 mi). The first line links the cities of Ain Sukhna and Marsa Matrouh, the second connects the cities of Sixth of October and Abu Simbel, and the third connects the city of Qena with the cities of Hurghada and Safaga.','description2':'The 2,000-kilometer high-speed rail network will connect 60 stations throughout the country and enable around 500 million journeys a year, with trains that can operate at up to 230 km/hr.','url':'HSR-High-Speed-Rail','image':HSR1, 'images':[HSR1,HSR2,HSR3,HSR4,HSR5,HSR6,HSR7,HSR8,HSR9,HSR10,HSR11,HSR12,HSR13,HSR14,HSR15]},
        {'project-name':'Mansoura Bridge','description1':'The project is situated along the Nile River, the bridge spans an area of 460 m.','description2':'It consists of a wide walkway for pedestrians and a road bridge. The bridge has an attractive architectural arch and four Andalusian style towers. ','url':'Mansoura-Bridge','image':Mansoura1, 'images':[Mansoura1,Mansoura2,Mansoura3,Mansoura4]},
        {'project-name':'EL-Salam International Hospital','description1':'','description2':'','url':'EL-Salam-International-Hospital','image':AS1,'images':[AS1,AS2]},
        {'project-name':'Murcia-Almeria High Speed Mediterranean Corridor Platform Spain','description1':'Design review of nine bridges in Spain.  ','description2':'','url':'Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform','image':murciaImg1,'images':[murciaImg1,murciaImg2,murciaImg3,murciaImg4]},
        {'project-name':'Dry Dock Highway, AL BAHRAIN','description1':'We work as a third-party checker for this project situated in Al Bahrain. Our job consists of reviewing two footbridges and one culvert.','description2':'','url':'Dry-Dock-Highway-AL-BAHRAIN','image':dryImg1,'images':[dryImg1,dryImg2,dryImg3] }

    ]
    let dect={
        'Warraq-Nile-Bridge':0,
        'HSR-High-Speed-Rail':1,
        'Mansoura-Bridge':2,
        'EL-Salam-International-Hospital':3,
        'Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform':4,
        'Dry-Dock-Highway-AL-BAHRAIN':5
    }
  return (
    <>
        <Navbar active={props.active}/>
        {params.projectId && <HeaderSection primaryHeader={projects[dect[params.projectId]]['project-name']} secondaryHeader="Our Work"
        back_images={projects[dect[params.projectId]]['image']} />}
        {!params.projectId && <HeaderSection primaryHeader="Our Projects" secondaryHeader="Our Work"
        back_images={CollageImage} />}
        {params.projectId && <ServiceDescription description1={projects[dect[params.projectId]]['description1']} description2={projects[dect[params.projectId]]['description2']}/>}
        {params.projectId && <RelatedWorks secondary="" primary="" projects={[]} images={projects[dect[params.projectId]]['images']}/>}
        {!params.projectId && <RelatedWorks secondary="" primary="" projects={projects} images={[]}/>}
        
        <Footer_section/>
    </>
    
  )
}

export default Projects