import React from 'react'
import drImage from '../images/dr-gamal.webp'
import './ChairmanStyle.css'

function ChairmanSection() {
  return (
    <>
      <div className='chairman'>
          <div className='containeer'>
              <h3>Leadership</h3>
              <h1>Our Chairman</h1>
              <div className='chairman-grid'>
                    <div className='image-half'>
                        <div className='dr-image' style={{
        backgroundImage: `url(${drImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'

    }}>
                        </div>
                    </div>
                    <div className='article-half'>
                        <h1 className='special'>Dr. Gamal Helmy, Ph.D., PE</h1>
                        <p>
                        Dr. Helmy is a Senior Bridge, Structural and Professional Engineer.  He has an extensive experience in the bridges/structural design, construction and supervision of a wide range of bridges, structures and industrial projects in several countries.
                        </p>
                        <p>
                        He has acquired a considerable know-how related to site works in the course of performing his duties as structural designer and project coordinator during construction stages.  He was also involved in the design and erection of piles in shallow and deep water, reclamation studies for the creation of islands. He was also involved in the design and erection of scaffolding and formworks of major structures. 
                        </p>
                        <p>
                        Dr. Helmy has prepared international tenders, technical specifications and projects design criteria based on international standards, including AASHTO, ACI, Eurocode and BS codes.
                        </p>
                        <p>
                        Dr. Helmy is currently CEO of leading, pioneer consulting firm and currently running mega project in Egypt such High Speed Rail (HSR) Project, Main bridge crossing the Nile and others.
                        </p>
                    </div>
              </div>
          </div>
      </div>
    </>
    
  )
}

export default ChairmanSection