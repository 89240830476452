import React, { useEffect, useState } from 'react';
import ImageCard2 from './ImageCard2';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './TeamStyle.css';

function TeamSection() {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch('https://www.bi.cdgcairo.com/get_team_members.php') // Replace with your API endpoint
      .then((response) => response.json())
      .then((data) => setTeamData(data))
      .catch((error) => console.error('Error fetching team data:', error));
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className='team'>
        <div className='containeer'>
          <h1>Key Persons</h1>
          <Carousel responsive={responsive}>
            {teamData.map((person) => (
              <ImageCard2 key={person.id} Name={person.name} ImageSource={'https://www.bi.cdgcairo.com/uploads/'+person.image} />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default TeamSection;
