import React from 'react'
import './latest_projects.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from './Card';
import murciaImg from '../images/projects/Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform/1.webp'
import dryImg from '../images/projects/Dry-Dock-Highway-AL-BAHRAIN/1.webp'
import HSR from '../images/projects/HSR-High-Speed-Rail/1.webp'



import AS1 from '../images/projects/EL-Salam-International-Hospital/1.webp'
import warraqImg from '../images/projects/Warraq-Nile-Bridge/1.webp'


import img1 from '../images/latestprojects/1.webp'
import img2 from '../images/latestprojects/2.webp'
import img3 from '../images/latestprojects/4.webp'
import img4 from '../images/latestprojects/3.webp'


function Latest_projects() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1150, min: 700 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 700, min: 0 },
          items: 1
        }
      };
  return (
    <div className='latest-container'>
        
        <div className='sub_container'>
            <h1>Latest Projects</h1>
            <Carousel responsive={responsive}>
                <Card ImageSource={warraqImg} ProjectName='Warraq Nile Bridge' URL='Warraq-Nile-Bridge'/>
                <Card ImageSource={AS1} ProjectName='EL-Salam International Hospital' URL='EL-Salam-International-Hospital'/>
                <Card ImageSource={HSR} ProjectName='HSR-High Speed Rail' URL='HSR-High-Speed-Rail'/>
                <Card ImageSource={img4} ProjectName='Mansoura Bridge' URL='Mansoura-Bridge'/>
                <Card ImageSource={murciaImg} ProjectName='Murcia-Almeria' URL='Murcia-Almeria-High-Speed-Mediterranean-Corridor-Platform'/>
                <Card ImageSource={dryImg} ProjectName='Dry Dock Highway, AL BAHRAIN' URL='Dry-Dock-Highway-AL-BAHRAIN'/>
            </Carousel>
        </div>

    </div>
  )
}

export default Latest_projects