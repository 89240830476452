import React from 'react'
import './ServiceDescriptionStyle.css'

function ServiceDescription(props) {
  return (
    <>
        <div className='Description-Containeer'>
            <p>{props.description1}</p>
            <p>{props.description2}</p>
        </div>
    </>
  )
}

export default ServiceDescription