import React from 'react'
import './footer.css'


function Footer_section() {
  return (
    <div className='footer-containeer' id='footer'>
        <div className='sub-containeer'>
            <div className='row'>
            <div className="footer-col">
              <h4>Email</h4>
              <ul>
                <li><a href="mailto: Gamal.Helmy@CDGcairo.com">Gamal.Helmy@CDGcairo.com</a></li>
                <li><a href="mailto: CDG@CDGcairo.com">CDG@CDGcairo.com</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Mobile</h4>
              <ul>
                <li><a href="tel:+201060555538">+2 010 605 55 538</a></li>
                <li><a href="tel:+201000039225 ">+2 010 000 39 225 </a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Location</h4>
              <ul>
                <li><a>El Tagamoa, New Cairo , Egypt</a></li>
                <li><a>Mohandessin, Cairo, Egypt</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Follow Us</h4>
              <div className="social-links">
                <a target="_blank" href="https://www.facebook.com/profile.php?id=100091322651370&mibextid=ZbWKwL"><svg xmlns="http://www.w3.org/2000/svg" className='icon'  viewBox="0 0 30 30" width="120px" height="120px">    <path className='icon-color facebook' d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"/></svg></a>
                <a target="_blank" href="https://www.linkedin.com/company/cdgcairo"><svg xmlns="http://www.w3.org/2000/svg" className='icon'  viewBox="0 0 30 30" width="120px" height="120px">    <path className='icon-color linked' d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"/></svg></a>
                <a target="_blank" href="https://www.youtube.com/@CDGCairo"><svg xmlns="http://www.w3.org/2000/svg" className='icon'  viewBox="0 0 30 30" width="120px" height="120px">    <path className='icon-color youtube' d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"/></svg></a>
              </div>
            </div>
            </div>
            
        </div>
    </div>
  )
}

export default Footer_section