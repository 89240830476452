import React from 'react'
import Card from './Card'
import './ImageCard.css'
import {Carousel} from './Carousel'
import './PopSlider.css'
import { useState, useEffect } from 'react'
import './RelatedWorks.css'

function RelatedWorks(props) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [visiable,setVisable]=useState(false);
  const [imgIndex,setImgIndex]=useState(1);

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])

  let images=props.images;
  return (
    <>
      {visiable && <div className='pop-container'>
          
          <div className='close-div'>
            <div onClick={() => {setVisable(false)}} className='close-btn'>
                <p>X</p>
            </div>
          </div>
          <div className='image-slider'>
              <Carousel index={imgIndex}>
              { images.map((image,index)=>{
                return <img style={{
                  width: (screenSize.width-50),
                  height: (screenSize.width-50)*0.56
                }} src={image} key={index}/>
              })}
              </Carousel>
          </div>
      </div>}

      <div className='related-works'>
          <div className='containeer'>
              <h3>{props.secondary}</h3>
              <h1>{props.primary}</h1>
              <div className='works-grid'>
                  {props.projects.map((project,index)=>{
                      return <Card key={index} ImageSource={require('../images/projects/'+project['url']+'/1.webp')} ProjectName={project['project-name']} URL={project['url']}/>
                  })}
                  {props.images.map((image,index)=>{
                      // return <div key={index} onClick={() => {setImgIndex(index)
                      //   setVisable(true)}} ><ImageCard ImageSource={image}/></div>

                      return <div key={index} onClick={() => {setImgIndex(index)
                            setVisable(true)}} className='image-card'>
                          <img src={image}/>
                          <div className='intro'>
                              <div className='column-btn'>
                                  Expand
                              </div>
                          </div>
                      </div>
                  })}


              </div>
          </div>
      </div>
    </>
    
  )
}

export default RelatedWorks