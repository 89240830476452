import React from 'react'
import {useEffect} from 'react';
import Navbar from '../components/navbar'; 
import Hero_section from '../components/Hero_section';
import About_section from '../components/about_section';
import Latest_projects_section from '../components/latest_projects_section';
import Services_section from '../components/services_section';
import Footer_section from '../components/footer_section'


function Home(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
        <Navbar active={props.active}/>     
        <Hero_section/>
        <About_section/>
        <Latest_projects_section/>
        <Services_section/>
        <Footer_section/>
    </>
    
  )
}

export default Home