import {NavLink} from 'react-router-dom'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import logo from '../images/Logo.webp'
import React from 'react'
import {useState} from 'react';
import './navbar.css';


function Navbar(props) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
  };
  return (
    <nav>
        <ul className="nav__links">
            <li><NavLink to="/"><img className='logo' src={logo}/></NavLink></li>
            <li className={isActive ? 'active-display item' : 'item'} onClick={handleClick}><NavLink className={props.active==='1' ? 'custom-active' :''} to="/">HOME</NavLink></li>
            <li className={isActive ? 'active-display item' : 'item'} onClick={handleClick}><NavHashLink className={props.active==='2' ? 'custom-active' :''} to="/#services">SERVICES</NavHashLink></li>
            <li className={isActive ? 'active-display item' : 'item'} onClick={handleClick}><NavLink className={props.active==='3' ? 'custom-active' :''} to="/projects">PROJECTS</NavLink></li>
            <li className={isActive ? 'active-display item' : 'item'} onClick={handleClick}><NavLink className={props.active==='4' ? 'custom-active' :''} to="/aboutus">ABOUT US</NavLink></li>
            <li className={isActive ? 'active-display item' : 'item'} onClick={handleClick}><a href='#footer'><button>CONTACT US</button><span className='contact-mobile'>CONTACT US</span></a></li>
            <li className="toggle" onClick={handleClick}><span className="bars"></span></li>
        </ul>
        
    </nav>
  )
}

export default Navbar
